<template>
  <PageHeaderThunder
    v-if="config.theme === 'thunder' || isDKMarket"
    :test-mode-status="testModeStatus"
    :take-over-header="takeOverHeader"
    :test-mode-active="testModeActive"
    :is-mobile="uiStore.isMobile"
    :is-tablet="uiStore.isTablet"
  />
  <header
    v-if="config.theme !== 'thunder' && !isDKMarket"
    class="header h-[65px] md:h-120 bg-neutral-lightest w-full sticky z-header"
    :class="{
      'top-0': !globalContentStore.showStickyWarning,
      'top-[100px]': globalContentStore.showStickyWarning
    }"
  >
    <!--    <DebugOutput>{{ headerConfig.mainMenuContent }}</DebugOutput>
    <DebugOutput position="right">{{ headerConfig.rightMenuContent }}</DebugOutput>-->
    <div class="relative z-header bg-neutral-lightest border-b border-neutral-light">
      <!-- row 1 -->
      <div class=" md:border-b border-neutral-light">
        <div class="layout-container flex justify-between h-64 items-center gap-16">
          <div class="basis-1/2">
            <div class="flex md:hidden justify-start items-center gap-16">
              <button @click="onClickMenu">
                <img src="/zynicons/hamburger.svg" class="h-24">
              </button>
              <button @click="uiStore.setShowSearch(true)">
                <img src="/zynicons/search.svg" class="h-24">
              </button>
            </div>
            <div class="relative hidden md:block">
              <ClientOnly><SearchForm /></ClientOnly>
            </div>
          </div>
          <div class="basis-56 md:basis-[68px] shrink-0">
            <nuxt-link :to="config.currentMarket?.url || '/'">
              <img
                src="/icons/zyn-new-logo-blue.svg"
                class="h-20 md:h-24 cursor-pointer object-contain select-none object-left"
                alt="Zyn logo"
              >
            </nuxt-link>
          </div>
          <div class="basis-1/2 flex justify-end gap-16 md:gap-24 items-center">
            <button v-if="showImpersonate" @click="toggleImpersonateModal">
              <img src="/zynicons/impersonate.svg" class="h-24">
            </button>
            <button @click="onOpenUserModal">
              <img src="/zynicons/user.svg" class="h-24">
            </button>
            <nuxt-link :to="favouritesPage" class="hidden md:block cursor-pointer">
              <img src="/zynicons/bookmark.svg" class="h-24">
            </nuxt-link>

            <!-- buy, regular -->
            <button
              v-if="currentMarket && currentMarket.enablePurchases"
              class="relative"
              @click="toggleCartMenu()"
            >
              <div class="absolute text-10 leading-single top-10 w-full text-center">{{ basketQuantity }}</div>
              <img src="/zynicons/basket.svg" class="h-24">
            </button>
          </div>
        </div>
      </div>
      <!-- desktop row 2 -->
      <div class="hidden layout-container md:flex justify-between items-center h-56">
        <div class="flex justify-start items-center gap-24">
          <div
            v-for="(menuItem, menuIndex) in headerConfig.mainMenuContent"
            :key="`left${menuIndex}`"
            class="header-link relative h-56
              type-tag-xs leading-single whitespace-nowrap uppercase select-none text-brand-dark
              flex items-center"
            :class="{ 'header-link--children': menuItem.linkColumns }"
            @mouseover="setDropdownContent(menuItem)"
            @mouseenter="setDropdownContent(menuItem)"
            @mouseleave="hideDropdown"
          >
            <nuxt-link
              v-if="menuItem.href"
              :to="menuItem.href"
              :title="menuItem.text"
            >
              {{ menuItem.text }}
            </nuxt-link>
            <div v-else class="inline-block cursor-default">
              {{ menuItem.text }}
            </div>
            <img
              v-if="menuItem.linkColumns"
              src="/zynicons/chevron-down.svg"
              class="h-14 ml-8"
            >
          </div>
        </div>
        <div v-if="headerConfig?.rightMenuContent?.length" class="flex justify-end gap-24">
          <div
            v-for="(menuItem, menuIndex) in headerConfig.rightMenuContent"
            :key="`right${menuIndex}`"
            class="header-link relative h-56
              type-tag-xs leading-single whitespace-nowrap uppercase select-none text-brand-dark
              flex items-center"
            :class="{ 'header-link--children': menuItem.linkColumns }"
            @mouseover="setDropdownContent(menuItem)"
            @mouseenter="setDropdownContent(menuItem)"
            @mouseleave="hideDropdown"
          >
            <nuxt-link
              v-if="menuItem.href"
              :to="menuItem.href"
              :title="menuItem.text"
            >
              {{ menuItem.text }}
            </nuxt-link>
            <div v-else class="inline-block cursor-default">
              {{ menuItem.text }}
            </div>
            <img
              v-if="menuItem.linkColumns"
              src="/zynicons/chevron-down.svg"
              class="h-14 ml-8"
            >
          </div>
        </div>
      </div>
    </div>

    <!-- desktop dropdown -->
    <transition name="slideInTop">
      <div
        v-if="showDropdown"
        class="absolute z-dropdown  w-full
                bg-neutral-lightest border-b border-neutral-light"
        @mouseover="clearDropdownHide"
        @mouseenter="clearDropdownHide"
        @mouseleave="hideDropdown"
      >
        <div class="layout-container">
          <div v-if="dropdownContent" class="flex py-48 gap-24 justify-start">
            <div
              v-for="menuChildren in dropdownContent.linkColumns"
              :key="menuChildren.id"
              class=""
              :class="{
                'basis-[336px]': !menuChildren.heading,
                'basis-[216px]': menuChildren.heading,
              }"
            >
              <div
                v-if="menuChildren.heading"
                class="type-tag-xxs uppercase mb-16"
              >
                {{ menuChildren.heading }}
              </div>
              <nuxt-link
                v-for="child in menuChildren.links"
                :key="child.id"
                :to="child.href"
                :title="child.text"
                class="block last:mb-0 leading-single text-brand-dark"
                :class="{
                  'type-headline-sm mb-24': !menuChildren.heading,
                  'type-headline-sm mb-16': menuChildren.heading,
                }"
              >
                {{ child.text }}
              </nuxt-link>
            </div>
            <template v-for="(feature, fIndex) in dropDownFeatures" :key="fIndex">
              <component
                :is="feature.href ? NuxtLink:'div'"
                :to="feature.href"
                class="basis-[336px]"
              >
                <NuxtImg
                  v-if="feature.imageUrl"
                  :src="getImagePrefix(feature.imageUrl)"
                  class="mb-16 w-full aspect-16/9 object-cover"
                  preset="standard"
                  sizes="100vw md:336px"
                />
                <div v-if="feature.heading" class="type-headline-sm text-brand-dark">{{ feature.heading }}</div>
              </component>
            </template>
            <template v-for="(feature, fIndex) in dropDownEvents" :key="fIndex">
              <component
                :is="feature.href ? NuxtLink:'div'"
                :to="feature.href"
                class="basis-[336px] relative"
              >
                <div
                  v-if="feature.startDate"
                  class="absolute left-24 top-24 w-56 text-center"
                >
                  <div
                    class="bg-critical-primary py-4
                    capitalize type-headline-xs leading-single text-neutral-lightest
                  "
                  >
                    {{ formatDate(feature.startDate, 'm') }}
                  </div>
                  <div class="bg-neutral-lightest type-headline-md leading-single py-8">
                    {{ formatDate(feature.startDate, 'd') }}
                  </div>
                </div>
                <NuxtImg
                  v-if="feature.imageUrl"
                  :src="getImagePrefix(feature.imageUrl)"
                  class="mb-16 w-full aspect-16/9 object-cover"
                  preset="standard"
                  sizes="100vw md:336px"
                />
                <div v-if="feature.heading" class="type-headline-base text-brand-dark">{{ feature.heading }}</div>
                <div v-if="feature.startDate" class="type-xs text-neutral-dark">
                  <span>{{ formatDate(feature.startDate, 'md') }}</span>
                  <span v-if="feature.endDate"> - {{ formatDate(feature.endDate, 'md') }}</span>
                </div>
              </component>
            </template>
            <template v-for="(feature, fIndex) in dropDownNews" :key="fIndex">
              <component
                :is="feature.href ? NuxtLink:'div'"
                :to="feature.href"
                class="basis-[336px]"
              >
                <NuxtImg
                  v-if="feature.imageUrl"
                  :src="getImagePrefix(feature.imageUrl)"
                  class="mb-16 w-full aspect-16/9 object-cover"
                  preset="standard"
                  sizes="100vw md:336px"
                />
                <div v-if="feature.heading" class="type-headline-base text-brand-dark">{{ feature.heading }}</div>
              </component>
            </template>
          </div>
        </div>
      </div>
    </transition>
  </header>

  <transition name="fade-modal">
    <div v-if="showOverlay" class="fixed bg-neutral-darkest opacity-40 z-belowHeader top-0 left-0 w-screen h-screen" />
  </transition>

  <Transition name="fade-modal" class="z-modal relative">
    <UserModal v-if="showUserModal" @close-modal="onCloseUserModal" />
  </Transition>

  <ImpersonateModal
    v-if="showImpersonateModal"
    @close-modal="onCloseImpersonateModal"
  />
  <TestModeBanner
    v-if="testModeActive && config.isLoggedInAsAdmin"
    @deactivate-test-mode-click="$emit('deactivate-test-mode-click')"
  />

  <!-- mobile menu -->
  <Overlay
    class="z-aboveHeader"
    :overlay-active="overlayActive"
    @close-overlay="closeOverlay"
  >
    <transition name="slideInLeft">
      <MobileMenu
        v-if="showContent"
        :top-level="true"
        @close="closeOverlay"
      />
    </transition>
  </Overlay>
</template>

<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import { useCartStore } from '~/store/cart';

import { useUiStore } from '~/store/ui';
import { type IMenuItem, PurchaseType } from '~/models/api-types';
import PopUpTypes from '~/constants/pop-up-types';

import UserModal from '~/components/modals/UserModal.vue';
import ImpersonateModal from '~/components/modals/ImpersonateModal.vue';
import Overlay from '~/components/body/Overlay.vue';
import TestModeBanner from '~/components/header/TestModeBanner.vue';
import SearchForm from '~/components/header/SearchForm.vue';
import PageHeaderThunder from '~/components/body/PageHeaderThunder.vue';
import MobileMenu from '~/components/header/MobileMenu.vue';

const { formatDate } = useDateFormat();
const { getImagePrefix } = useImages();
const globalContentStore = useGlobalContentStore();
const config = useGlobalContentStore().config;
const cart = useCartStore();
const headerConfig = useGlobalContentStore().header;
const currentMarket = useGlobalContentStore().currentMarket;

const favouritesPage = globalContentStore.config.currentMarket?.favouritesPageUrl;

const uiStore = useUiStore();
const router = useRouter();

const overlayActive = ref(false);
const showContent = ref(false);
const showSearchForm = ref(false);
const showImpersonateModal = ref(false);
const showUserModal = ref(false);

const showOverlay = ref(false);
const NuxtLink = resolveComponent('NuxtLink');
const open = ref();

interface Props {
  testModeStatus?: boolean,
  takeOverHeader?: boolean,
  testModeActive?: boolean,
}

withDefaults(defineProps<Props>(), {
  testModeStatus: false,
  takeOverHeader: false,
  testModeActive: false,
});

defineEmits<{
  (event: 'deactivate-test-mode-click'): void,
}>();

const basketQuantity = computed(() => {
  if (cart.lastUpdatedCart === PurchaseType.Subscription && cart.subscriptionItems) {
    return cart.subscriptionItems?.length;
  } else if (cart.items) {
    return cart.items?.length;
  }
  return 0;
});

onMounted(async() => {
  const isCheckoutConfirmationPage =
    config.page?.contentType?.includes('CheckoutConfirmationPage') === true;

  if (!isCheckoutConfirmationPage && config.currentMarket?.enablePurchases) {
    setTimeout(async() => {
      await cart.fetchCart();
    }, 0);
  }
});

const onClickMenu = () => {
  if (overlayActive.value) {
    closeOverlay();
  } else {
    openOverlay();
  }
};
const openOverlay = () => {
  overlayActive.value = true;
  document.body.classList.add('locked');
  setTimeout(() => {
    showContent.value = true;
  }, 200);
};
const closeOverlay = () => {
  showContent.value = false;
  document.body.classList.remove('locked');
  setTimeout(() => (overlayActive.value = false), 200);
};

const { $event } = useNuxtApp();

const toggleCartMenu = () => {
  uiStore.setShowCartMenu(true);

  window.dataLayer?.push({
    event: 'Cart',
    category: 'cart',
    action: 'view',
  });
};

const toggleImpersonateModal = () => {
  if (showImpersonateModal.value) {
    onCloseImpersonateModal();
  } else {
    onOpenImpersonateModal();
  }
};

const onOpenImpersonateModal = () => {
  showImpersonateModal.value = true;
  $event('close-pop-up');
};
const onOpenUserModal = () => {
  if (globalContentStore.isLoggedIn) {
    router.push({ path: config.currentMarket?.myAccountPageUrl || '/' } );
  } else {
    showUserModal.value = true;
    $event('close-pop-up');
  }
};
const onCloseUserModal = () => {
  document.body.classList.remove('locked');
  showUserModal.value = false;
  uiStore.setPreventNavigation(false);
};

const onCloseImpersonateModal = () => {
  document.body.classList.remove('locked');
  showImpersonateModal.value = false;
};

// close menu when route change
const route = useRoute();
watch(
  () => route.fullPath,
  () => {
    closeOverlay();
    onCloseUserModal();
  }
);

const showDropdown = ref(false);
const hideDropdownTimer = ref<ReturnType<typeof setTimeout> | null>(null);
const dropdownContent = ref<IMenuItem>([]);

// new desktop dropdown
const setDropdownContent = (content: IMenuItem) => {
  if (
    content?.linkColumns?.length ||
    content?.featuredContentItems?.length
  ) {
    clearDropdownHide();
    dropdownContent.value = content;
    showOverlay.value = true;
    showDropdown.value = true;
  } else {
    clearDropdownHide();
    hideDropdown();
  }
};
const hideDropdown = () => {
  clearDropdownHide();
  hideDropdownTimer.value = setTimeout(()=> {
    showDropdown.value = false;
    showOverlay.value = false;
  }, 300);
};
const clearDropdownHide = () => {
  if (typeof hideDropdownTimer.value === 'number') {
    clearTimeout(hideDropdownTimer.value);
  }
};

const bigSpotsLeft = computed(()=> {
  const navCols = dropdownContent.value.linkColumns?.length || 0;
  const spotsLeft = Math.min(3, 5 - navCols);
  return Math.max(spotsLeft, 0);
});

const dropDownFeatures = computed(()=> {
  if (dropdownContent.value.featuredContentItems?.length && bigSpotsLeft.value > 0) {
    return dropdownContent.value.featuredContentItems.slice(0,bigSpotsLeft.value);
  }
  return [];
});
const dropDownEvents = computed(()=> {
  const left = bigSpotsLeft.value - dropDownFeatures.value.length;
  if (dropdownContent.value.latestEvents?.length && left > 0) {
    return dropdownContent.value.latestEvents
      .slice()
      .sort((a, b) => new Date(a.startDate) - new Date(b.startDate)) // Sort by startDate
      .slice(0, left);
  }
  return [];
});

const dropDownNews = computed(()=> {
  const left = bigSpotsLeft.value - dropDownFeatures.value.length - dropDownEvents.value.length;
  if (dropdownContent.value.latestNews?.length && left > 0) {
    return dropdownContent.value.latestNews.slice(0, left);
  }
  return [];
});

const isDKMarket = computed(() => {
  return config.currentMarket?.countryCode === 'DK';
});

const isLoggedInAsImpersonator = computed(() => {
  return config.isLoggedInAsImpersonator;
});
const isImpersonating = computed(() => {
  return config.isImpersonating;
});

const showImpersonate = computed(() => {
  return (
    config.isLoggedInAsAdmin ||
    config.isLoggedInAsImpersonator ||
    config.isImpersonating
  );
});

</script>

<style lang="postcss" scoped>
.header-link--children {
  &:hover {
    &::after {
      @apply opacity-100;
    }
  }
}
</style>
