<template>
  <footer class="bg-brand-dark">
    <!-- subscribe newsletter -->
    <div
      v-if="shouldShowNewsletterBox"
      id="newsletter"
      ref="newsLetter"
      class="py-56 bg-neutral-lighter"
    >
      <div class="layout-container flex justify-center text-center">
        <div v-if="newsletterIsSubscribed">
          <div class="mb-12 type-tag-xs text-neutral-dark">
            {{ footerConfig?.newsLetter.byline }}
          </div>
          <div class="mb-12 type-headline-4xl text-brand-dark">
            {{ footerConfig?.newsLetter.successHeading }}
          </div>
          <div class="mb-32 type-base text-neutral-darker">
            {{ footerConfig?.newsLetter.successMessage }}
          </div>
          <nuxt-link
            v-if="footerConfig?.newsLetter.successButton"
            :to="footerConfig?.newsLetter.successButton.href"
            :target="footerConfig?.newsLetter.successButton.target"
            :title="footerConfig?.newsLetter.successButton.title"
            class="btn btn-primary type-tag-xs btn--wide md:w-auto"
          >
            {{ footerConfig?.newsLetter.successButton.text }}
          </nuxt-link>
        </div>
        <div v-else class="md:w-3/5 text-center">
          <div class="mb-12 type-tag-xs text-neutral-dark">
            {{ footerConfig?.newsLetter.byline }}
          </div>
          <div class="mb-12 type-headline-4xl text-brand-dark">
            {{ footerConfig?.newsLetter.heading }}
          </div>
          <div class="flex mt-16">
            <WysiwygWrapper
              :style-config="{ 'wysiwyg type-base text-neutral-darker': true }"
              :html="footerConfig?.newsLetter.preamble"
            />
          </div>
          <form autocomplete="off">
            <div class="relative w-full mt-32">
              <input
                id="footerNewsletterField"
                v-model="newsletterEmail"
                type="email"
                required
                :placeholder="$lang('footerResources', 'emailAddress')"
                class="px-16 h-56 w-full type-xs border border-neutral-light placeholder:type-tag-xxs placeholder:uppercase placeholder:text-neutral-dark placeholder:relative placeholder:-top-2 pr-20 mb-8 md:mb-0 !text-16"
                @input="(e) => onEmailChange(e.target.value)"
                @keyup.enter="onSubmitNewsLetter($event)"
              >
              <button
                class="btn btn-primary btn--md type-tag-xs btn--wide md:absolute md:top-1/2 md:transform md:-translate-y-1/2 md:right-8 md:w-auto !h-48 px-4"
                @click="onSubmitNewsLetter($event)"
              >
                {{ footerConfig?.newsLetter.buttonText }}
              </button>
            </div>
          </form>
          <div v-if="newsletterError" class="mt-4 type-xs text-left text-critical-primary">
            {{ newsletterError }}
          </div>
          <div class="flex mt-24">
            <WysiwygWrapper
              :style-config="{ 'wysiwyg type-xs w-full': true }"
              :html="footerConfig?.newsLetter.termsAndConditions"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- actual footer -->
    <div class="layout-container py-48 bg-brand-dark text-brand-light type-sm md:type-xs">
      <div class="md:flex justify-between gap-24">
        <!-- extendable menus -->
        <MenuFoldOut
          v-for="subMenu in footerConfig?.subMenu"
          :id="'subMenuMobileFooter' + subMenu.heading"
          :key="'subMenuMobileFooter' + subMenu.heading"
          :heading="subMenu.heading || ''"
          :toggled-row="toggledMenuRow"
          :items="subMenu.links"
          :color-scheme="'dark'"
          :is-mobile="isMobile"
          class="border-[#3d6984] border-b"
          :class="desktopColumns"
          @toggle-menu-row="toggleMenuRow"
        />

        <!-- social -->
        <div :class="desktopColumns">
          <MenuFoldOut
            id="social"
            heading="Social"
            :items="socialLinks"
            :toggled-row="toggledMenuRow"
            color-scheme="dark"
            direction="left"
            @toggle-menu-row="toggleMenuRow"
          />
        </div>
      </div>

      <!-- payment icons, shipping icons -->
      <div v-if="purchaseEnabled" class="mt-48 md:flex items-center gap-48">
        <!-- payment -->
        <div v-if="footerConfig?.paymentLogos" class="md:flex items-center">
          <div class="mobOnly:text-center uppercase type-tag-xxs md:mr-12">
            {{ $lang('footerResources', 'paymentOptionsHeader') }}:
          </div>
          <div class="flex pt-12 md:mb-12 flex-wrap justify-center gap-12">
            <img
              v-for="logo in footerConfig.paymentLogos"
              :key="'footerPaymentLogo-' + logo"
              class="max-h-24"
              :src="logo"
              height="24"
              alt="Footer payment logo"
            >
          </div>
        </div>
        <!-- shipping -->
        <div v-if="footerConfig?.deliveryLogos" class="md:flex items-center mobOnly:mt-48">
          <div class="mobOnly:text-center uppercase type-tag-xxs md:mr-12">
            {{ $lang('footerResources', 'shippingOptionsHeader') }}:
          </div>
          <div class="flex pt-12 md:mb-12 flex-wrap justify-center gap-12">
            <img
              v-for="logo in footerConfig.deliveryLogos"
              :key="'footerPaymentLogo-' + logo"
              class="max-h-24"
              :src="logo"
              height="24"
              alt="Footer payment logo"
            >
          </div>
        </div>
        <!-- shipping -->
        <div v-if="footerConfig?.ageRestrictionImage" class="mobOnly:mt-48 flex justify-center">
          <img
            :src="footerConfig.ageRestrictionImage"
            class="max-h-24"
            alt="18+"
          >
        </div>
      </div>
    </div>
    <div class=" bg-brand-dark border-[#3d6984] border-t pt-48" />
    <div class="layout-container  bg-brand-dark text-brand-light pb-48 ">
      <!-- bottom text and bottom links -->
      <div class="md:flex justify-between items-center">
        <!-- text -->

        <div class="basis-1/2">
          <WysiwygWrapper
            :style-config="{
              'type-tag-xxs mobOnly:text-center': true,
            }"
            :html="footerConfig?.companyInfo ?? ''"
          />
        </div>

        <!-- links -->
        <div class="mobOnly:mt-48 basis-1/2 flex flex-wrap gap-12 justify-center md:justify-end">
          <div
            v-for="metaLink in footerConfig?.metaLinks"
            :key="'metaMenuFooterItem-' + metaLink.text"
            class="type-tag-xs cursor-pointer select-none"
          >
            <nuxt-link
              :to="metaLink.href"
              :target="metaLink.target"
              :title="metaLink.title"
              class="whitespace-nowrap text-white"
            >
              {{ metaLink.text }}
            </nuxt-link>
          </div>
          <ClientOnly>
            <div
              v-if="isOneTrustLoaded"
              class="type-tag-xs cursor-pointer select-none"
            >
              <a
                id="ot-sdk-btn"
                data-onetrust="v3"
                rel="noopener noreferrer"
                class="optanon-toggle-display whitespace-nowrap"
              >
                {{ $lang('footerResources', 'cookieSettingsHeader') }}
              </a>
            </div>
          </ClientOnly>
        </div>
      </div>

      <div class="layout-container">
        <div v-if="shouldShowMarketDisplay" class="flex">
          <MarketDisplay :color-scheme="'dark'" />
        </div>
      </div>
    </div>
    <div v-if="isLoggedInAsAdmin" class="pb-80 bg-grey800">
      <div class="layout-container text-white">
        <div
          class="btn btn--md"
          :class="{
            'btn--light-theme': testModeActive,
            'btn--light-ghost-theme': !testModeActive,
          }"
          @click="toggleTestMode"
        >
          <span v-if="testModeActive">{{
            $lang('sharedResources', 'deactivateTestMode')
          }}</span>
          <span v-else>{{ $lang('sharedResources', 'activateTestMode') }}</span>
        </div>
      </div>
    </div>
  </footer>
</template>
<script setup lang="ts">
import MarketDisplay from '../MarketDisplay.vue';
import MenuFoldOut, { type   MenuFoldOutLink } from '../body/MenuFoldOut.vue';
import WysiwygWrapper from '../WysiwygWrapper.vue';
import { type IMarket } from '~/models/api-types';
import { Sizes, Themes } from '~/constants/button-layouts';
import { useGlobalContentStore } from '~/store/globalContent';
import { useVoyadoStore } from '~/store/voyado';
import { usePageContentStore } from '~/store/pageContent';

const { $event } = useNuxtApp();
const config = useGlobalContentStore().config;
const voyadoStore = useVoyadoStore();
const pageStore = usePageContentStore();

const { apiPost, apiPut, apiDelete, lastError } = useApiFetch();
const { $lang } = useNuxtApp();

defineProps<{
  isMobile?: boolean,
}>();

const testModeActive = useCookie('test_order_active');
const toggledMenuRow = ref('');
const emailError = ref('');
const newsletterEmail = ref('');
const newsletterError = ref('');
const newsletterIsSubscribed = ref(false);
const newsletterSubmitLoading = ref(false);
const cookiebotActive = ref(false);

const footerConfig = computed(() => {
  return config.footer;
});

const shouldShowNewsletterBox = computed(() => {
  return !!config.currentMarket?.enableNewsletter && !!!config.page?.hideNewsletterFooter && !pageStore.hideNewsletterForThisPage;
});

const shouldShowMarketDisplay = computed(() => {
  return config.markets?.length > 1 && config.theme === 'thunder';
});

const purchaseEnabled = computed(() => {
  return config.currentMarket?.enablePurchases;
});

const isLoggedInAsAdmin = computed(() => {
  return config.isLoggedInAsAdmin;
});

onMounted(() => {
  if (testModeActive.value && !isLoggedInAsAdmin.value) {
    deactivateTestMode();
  }
});

const isOneTrustLoaded = computed(() => {
  const url = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
  if (typeof window !== 'undefined') {
    var scripts = document.getElementsByTagName('script');
    for (var i = scripts.length; i--;) {
      if (scripts[i].src.includes(url)) return true;
    }
  }
  return false;
});

const toggleMenuRow = (id: string) => {
  if (toggledMenuRow.value === id) {
    toggledMenuRow.value = '';
  } else {
    toggledMenuRow.value = id;
  }
};

const onEmailChange = (input: string) => {
  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input.trim()) && input) {
    emailError.value = $lang('footerResources', 'invalidEmail');
  } else {
    emailError.value = '';
  }
  newsletterEmail.value = input;
};

const onSubmitNewsLetter = async(event: Event) => {
  if (!!newsletterEmail.value && !emailError.value) {
    event.preventDefault();
    newsletterSubmitLoading.value = true;
    const { currentMarket, language } = config;
    const { countryCode, useVoyado } = currentMarket as IMarket;
    if (useVoyado) {
      const voyadoRes = await apiPost<string>(
        `voyado/newsletter?countryCode=${countryCode}&language=${language}`,
        {
          email: newsletterEmail.value,
          countryCode,
          language,
        }
      );
      voyadoStore.setContactId(voyadoRes);
      newsletterError.value = '';
      newsletterIsSubscribed.value = true;
      window.dataLayer?.push({
        event: 'newsletter_signup',
      });
      scrollToSection();
    }

    if (lastError.value) {
      const message = lastError.value || '';
      $event('trigger-error-bar', {
        message,
      });
    }
    newsletterSubmitLoading.value = false;
  } else {
    newsletterError.value = $lang('footerResources', 'incompleteData');
  }
};

const emit = defineEmits<{
  (event: 'setTestMode', value: string | null): void,
}>();

const toggleTestMode = async() => {
  if (testModeActive.value) {
    await apiDelete(`user/session/testmode?language=${config.language}&countryCode=${config.currentMarket?.countryCode}`);
    testModeActive.value = null;
  } else {
    await apiPut(
      `user/session/testmode?language=${config.language}&countryCode=${config.currentMarket?.countryCode}`
    );
    testModeActive.value = 'true';
  }
  emit('setTestMode', testModeActive.value);
};

const deactivateTestMode = async() => {
  await apiDelete(`user/session/testmode?language=${config.language}&countryCode=${config.currentMarket?.countryCode}`);
  testModeActive.value = null;
};

const scrollToSection = () => {
  const element = document.getElementById('newsletter');

  if (element) {
    const offset = element.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({
      top: offset - 140,
      left: 0,
      behavior: 'smooth',
    });
  }
};

const socialLinks = computed<MenuFoldOutLink[]>(()=> {
  if (footerConfig?.value?.socialLinks) {
    return footerConfig.value.socialLinks.map((s)=> {
      return {
        text: s.iconCssClass,
        href: s.linkUrl,
        icon: s.iconCssClass,
        target: '_blank',
      } as MenuFoldOutLink;
    });
  }
  return [];
});

// fixed menu + expandable + social
const desktopColumns = computed(() => {
  const exp = footerConfig?.value?.subMenu.length || 0;
  return `basis-1/${exp + 2}`;
});

defineExpose({
  toggleTestMode,
});

// hide newsletter error when route change
const route = useRoute();

watch(
  () => route.path,
  () => {
    newsletterError.value = '';
  }
);
</script>
